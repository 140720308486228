import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _58477c8b = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _5a9484e3 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _abc11fd8 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _cad89312 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _9911f4f0 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _1f845ef0 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _f27664d2 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _14c471b2 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _22378f58 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _6d3f62c6 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _84c40240 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _0fd3a42b = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _8fcab430 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _081ad3fa = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _e1eeeb28 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _09b71172 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _23c2b611 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _a405a5f2 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _5cdb435e = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _f352a5bc = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _58477c8b,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _5a9484e3,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _abc11fd8,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _cad89312,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _9911f4f0,
    name: "receipt"
  }, {
    path: "/search",
    component: _1f845ef0,
    name: "search"
  }, {
    path: "/search-booking",
    component: _f27664d2,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _14c471b2,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _22378f58,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _6d3f62c6,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _84c40240,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _0fd3a42b,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _8fcab430,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _081ad3fa,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _e1eeeb28,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _09b71172,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _23c2b611,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _a405a5f2,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _5cdb435e,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _f352a5bc,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
